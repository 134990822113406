.main-header {
	background-color: $white;
	width: 100%;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 1000;
	transition: $veryslow;
}
.fixed .main-header {
	box-shadow: $shadow;
}
.primary-nav {
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-content: stretch;
	align-items: flex-end;		
	transition: $slow;
	@include desktop {
		padding-top: 1rem;
	}
	&-grouping {
		&.left,
		&.right {
			display: none;
			width: 100%;
			@include desktop {
				display: block;
				flex: 1 1 auto;
				align-self: auto;
			}
		}			
		&.left {
			text-align: right;
		}
		&.right {
			text-align: left;
		}	
		&.logo-container {
			flex: 0 1 8rem;
			transition: $slow;
			@include tablet {
				flex: 0 1 11rem;
			}			
			@include desktop {
				flex: 0 1 20rem;
				align-self: baseline;
			}
			@include md {
				flex: 0 1 27rem;
				align-self: baseline;
			}			
		}
	}
	&-item {
		font-size: 1.1em;
		display: inline-block;
		color: $gray;
		text-decoration: none;
		cursor: pointer;	
		text-transform: uppercase;
		position: relative;
		@include tablet {
		font-size: .75em;
		}			
		@include desktop {
			font-size: 1em;
		}	
		@include md {
			font-size: 1.1em;
		}				
		&:hover {
			.primary-nav {
				transition: $slow;
			  &-link { 
  				color: $primary;
			  }			  
			}				
			.primary-subnav {
				opacity: 1;
				visibility: visible;
				transition: none;
			}
		}
		&:focus {
			.primary-nav {
			  &-link { 
  				color: $primary;
      		box-shadow: inset 0 0 0 2px $primary;
			  }			  
			}				
			.primary-subnav {
				opacity: 1;
				visibility: visible;
				transition: none;
			}
		}
		&.active {
  		&:before {
  			@include size(0, 0);
  			@include absolute(left 50% bottom 0%);			
  			z-index: 200;
  			border: solid transparent;
  			content: " ";
  			pointer-events: none;
  			border-color:  rgba($secondary, 0);
  			border-bottom-color: $secondary; 
  			border-width: .5rem;
  			margin-left: -.5rem;
  		}
  		&:hover {
  			border-color: darken($primary, 15%);
  			border-bottom-color: darken($primary, 15%); 
			}
		}
	}	
	&-list {
		display: inline-block;
		margin: 0 auto;
		padding: 0;
		font-family: $headline;
		list-style: none;
		position: relative;
	}
	&-link {
		color: $black;
		transition: $basic;	
		padding: .75rem 1rem;
		display: block;
		font-weight: 700;
		@include md {
  		padding: .75rem 1.5rem;
		}
		&:focus {
  		box-shadow: inset 0 0 0 2px $primary;
  		color: $primary;
		}
	}	
}
.main-logo {
	width: 100%;
	margin: 0 auto;	
	display: block;
	position: relative;
	z-index: 200;
	position: relative;
	padding: .5rem;
	&:focus {
		box-shadow: inset 0 0 0 2px $primary;
	}
	@include tablet {
	}
	.svg {
		width: 100%;
		max-height: 4rem;
		fill: $primary;
		transition: $basic;
	}
	&:hover {
		.svg {
			fill: $secondary;
		}
	}
}
.fixed {
	.primary-nav {
		@include desktop {
			padding-top: 0;
		}	
		&-grouping {	
			&.left,
			&.right {
				transition: $slow;
				opacity: 0;
				visibility: hidden;
			}
			&.logo-container {
				flex: 0 1 6rem;
				padding: .5rem;
				@include tablet {
					padding: .5rem;
					flex: 0 1 7rem;
				}			
				@include desktop {
  				flex: 0 1 20rem;
				}
			}
		}
	}
}
.primary-subnav {
	background: darken($primary, 10%);
	box-shadow: $shadow;
	width: 100%;
	min-width: 12rem;
	list-style: none;
	margin: 0;
	padding: .5rem;
	opacity: 0;
	visibility: hidden;
	@include absolute(top 100% left 50%);
	transform: translateX(-50%);
	transition: none;
	&:before {
		@include size(0, 0);
		@include absolute(left 50% bottom 100%);			
		z-index: 200;
		border: solid transparent;
		content: " ";
		pointer-events: none;
		border-color: rgba(darken($primary, 10%), 0);
		border-bottom-color: darken($primary, 10%); 
		border-width: .5rem;
		margin-left: -.5rem;				
	}		
	&-li {
		padding: 0;
		margin: 0;
		display: block;
	}
	&-link {
		display: block;
		padding: .75rem 1.25rem;
		color: $white;
		text-transform: none;
		font-family: $body;
		font-size: 1.3rem;
		font-weight: 300;
		text-align: left;
		transition: $slow;
		&:hover {
			background: $secondary;
		}
		&:focus {
			background: darken($secondary, 15%) !important;
		}		
	}
}
