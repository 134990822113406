@import url('https://fonts.googleapis.com/css?family=Montserrat:700|Roboto+Condensed:400,400i,700,700i&display=swap');

@import "global/globals"; 
@import "global/normalize"; 
@import "shell/header"; 
@import "shell/logo"; 
@import "shell/utility-nav";
@import "shell/hamburger";
@import "shell/social"; 
@import "shell/footer"; 
@import "shell/expanded-menu"; 
@import "shell/button"; 
@import "shell/join"; 
@import "shell/news-card"; 
@import "shell/promos"; 
@import "shell/from-leadership";  
@import "shell/benefits";  
@import "shell/featured-action";  
@import "shell/headlines";  
@import "shell/more-simple";  
@import "shell/modal";  
@import "home/difference";  

html {
	position: relative;	
}
body {
	background-color: $white; 
	font-family: $body;
	color: $text;
	text-align: center;
	&.mobile-menu-active { 
		overflow: hidden;
	}
} 
.fill-svg,
.screen-reader {
	@include screen-reader-text;
}

.grad-start {
	stop-color: $lightblue;
}
.grad-end  {
	stop-color: $primary;
}

.skip-to-main {
	background: $secondary;
	@include fixed(top 0% left 0%);
	z-index: 1000;
	width: 100%;	
	color: $white;
	display: block;
	max-height: 0;
	transition: $slow;
	&:focus {	
		max-height: 3rem;
		outline: 2px solid $secondary;
		padding: .5rem;	
		z-index: 1000000;
	}
}
