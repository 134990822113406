.button {
    &-block {
        display: block;
        @extend %slide-up;
        text-align: center;

        &.top-margin {
            margin-top: 1rem !important;

            @include desktop {
                margin-top: 1.5rem !important;
            }

            @include md {
                margin-top: 2rem !important;
            }
        }

        &.bot-margin {
            margin-bottom: 1rem;

            @include desktop {
                margin-bottom: 1.5rem;
            }
        }

        &.reveal {
            @extend %slide-up-reveal;
        }

        &.pre-headlines {
            display: inline-flex;
        }

        &.align-left {
            @include tablet {
                text-align: left;
            }
        }

        &.uniform-grid {
            @include desktop {
                margin-left: auto;
                margin-right: auto;
                display: flex;
                max-width: 93.75em;
            }
            .button {
                width: calc(50% - .5rem);

                @include tablet {
                    width: calc(50% - 1rem);
                }

                @include md {
                    width: calc(50% - 1.3rem);
                }
            }
        }

        &-grouping {
            padding: 0 .5rem;
            position: relative;
            @extend %slide-up;
            transition-delay: 1s !important;

            @include tablet {
                padding: 0 2rem;
            }

            &.reveal {
                @extend %slide-up-reveal;
            }

            @include tablet {
                &:first-of-type:after {
                    content: "";
                    width: 1px;
                    height: 3rem;
                    background: $white;
                    display: block;
                    @include absolute(right 0 top 50%);
                    transform: translateY(-10%);
                }
            }
        }

        &-title {
            color: $white;
            font-weight: 400;
            margin-bottom: .35rem;
            font-size: .85rem;
            display: block;

            @include tablet {
                font-size: 1.1rem;
                margin-bottom: .5rem;
            }

            @include desktop {
                font-size: 1.3rem;
                margin-bottom: .75rem;
            }
        }
    }

    background: darken($primary, 10%);
    border-radius: .3rem;
    padding: .85rem 1.3rem;
    font-family: $headline;
    display: inline-block !important;
    box-shadow: $button-shadow;
    position: relative;
    overflow: hidden;
    transition: $bounce;
    margin: .25rem;
    outline: 0;

    &.small {
        padding: .55rem .75rem;
    }

    @include tablet {
        padding: .754rem 1rem;
        margin: .5rem;

        &.small {
            padding: .75rem 1rem;
        }
    }

    @include md {
        padding: 1.25rem 2.25rem;
        margin: .65rem;

        &.small {
            padding: .85rem 1.25rem;
        }
    }

    &:before,
    &:after {
        @include size(100%, 50%);
        display: block;
        content: "";
        z-index: 10;
        transition: $bounce;
        opacity: .4;
    }

    &:before {
        @include absolute(top 0 left 0);
        background: linear-gradient(0deg, rgba($white, 0) 0%, rgba($white, .5) 100%);
    }

    &:after {
        @include absolute(bottom 0 left 0);
        background: linear-gradient(0deg, rgba($black, .5) 0%, rgba($black, 0) 100%);
    }

    &-icon {
       svg {
          width: 50%;
          max-width: 10rem;
          fill: white !important;
       }
    }

    &-text {
        position: relative;
        z-index: 200;
        text-transform: uppercase;
        font-size: .8rem;
        color: $white;
        white-space: nowrap;

        @include tablet {
            font-size: .9rem;
        }

        @include desktop {
            font-size: 1rem;
        }
    }

    &:hover {
        transform: scale(1.1);
        box-shadow: $button-shadow-hover;
        text-decoration: none !important;

        &:before,
        &:after {
            opacity: .8;
        }
    }

    &:active {
        transform: scale(.95);
        box-shadow: $button-shadow-hover;

        &:before,
        &:after {
            opacity: .3;
        }
    }

    &:focus {
        box-shadow: $button-shadow-hover, 0 0 0 4px $green;
    }

    &.blue {
        background: $secondary;
    }

    &.white {
        background: $white;

        .button-text {
            color: $primary;
        }
    }

    &.gray,
    &.dark-gray {
        background: $gray;

        .button-text {
            color: $white;
        }
    }
}
