.join {
	position: relative;
	&-grid, &-fields {
		width: 100%;
		@include tablet {
			display: flex;
			flex-wrap: nowrap;
			flex-direction: row;
			align-items: stretch;
			align-content: stretch;
		}
	}	
	&-layer {
			background: $darkgreen;
 		&.reveal {
 			@extend %slide-up-reveal;	
 		}
 		&.gray {
   			background: $lightgray !important;
 		}
	}
	&-col {
		position: relative;
		color: $white;
		text-align: left;
		@extend %slide-up;
		@include tablet {
			flex: 1;
			&:nth-child(2) {
				transition-delay: .2s;
			}	
		}	
  	&.submitted {
  	  .join-content {
  	    opacity: 0;
  	    visibility: hidden;
  	    z-index: 1; 
  	    &-thanks {
    	    opacity: 1;
    	    visibility: visible;
    	    z-index: 100;
  	    }
  	  }
  	}			
		&.email {
			background: $darkgreen;
		}
		&.sms {
			background: $secondary;
		}		
		&:after {
			
		}
		&.email {
			.join-content {
				padding-right: 2rem;
				@include tablet {
					padding-right: 6rem;
				}		
				@include desktop {
					padding-right: 8rem;
				}
				@include md {
					padding-right: 9rem;
				}	
				@include lg {
					padding-right: 10rem;
				}		
				@include xl {
					padding-right: 12rem;
				}						
				&-thanks {
				  text-align: left;
  				padding-right: 2rem;
  				@include tablet {
  					padding-right: 6rem;
  				}		
  				@include desktop {
  					padding-right: 12rem;
  				}				  
				}				
			}				
		}
		&.sms {
			.join-content {
				padding-left: 2rem;
				@include tablet {
					padding-left: 6rem;
				}		
				@include tablet {
					padding-left: 6rem;
				}		
				@include desktop {
					padding-left: 8rem;
				}
				@include md {
					padding-left: 9rem;
				}	
				@include lg {
					padding-left: 10rem;
				}		
				@include xl {
					padding-left: 12rem;
				}	
				&-thanks {
				  text-align: left;
  				padding-left: 2rem;
  				@include tablet {
  					padding-left: 6rem;
  				}		
  				@include desktop {
  					padding-left: 8rem;
  				}
  				@include md {
  					padding-left: 9rem;
  				}	
  				@include lg {
  					padding-left: 10rem;
  				}		
  				@include xl {
  					padding-left: 12rem;
  				}				  
				}
			}	
		}	
		&.reveal {
			@extend %slide-up-reveal;			
			.join-headline,
			.join-blurb,
			.join-form,
			.join-disclaimer {
				@extend %slide-up-reveal;
			}
		}	
		&.sms {
			.join {
				&-input {
					&:hover {
						background: darken($secondary, 10%);
						@include placeholder {
							color: $white;
						}
					}
					&:focus {
						background: $white;
      	box-shadow: 0 0 0 4px darken($blue, 10%);
						@include placeholder {
							color: darken($secondary, 20%);
						}					
					}
				}
				&-button {
					color: $secondary;					
					&:hover {
						background: darken($secondary, 10%);	
						color: $white;
					}
					&:focus {
     	box-shadow: 0 0 0 4px darken($blue, 20%);
					}
				}
			}
		}
	}
	&-center {
		@include size(100%, 100%);
		z-index: 400;
		align-items: center;
		display: flex;
		justify-content: center;
		overflow: hidden;	
	}	
	&-content {
		width: 100%;
		max-width: 50rem;
		text-align: left;
		padding: 2rem;
		transition: $slow;
		@include tablet {
			padding: 2rem 2rem;
		}		
		@include desktop {
			padding: 3rem 3rem;
		}
		@include md {
			padding: 4rem 4rem;
		}	
		@include lg {
			padding: 4.5rem 5rem;
		}		
		@include xl {
			padding: 5rem 7rem;
		}	
  	&-thanks {
   	@extend %block-padding-small;
 	  width: 100%;
 	  opacity: 0;
 	  visibility: hidden;
  		@include absolute(top 50% right 0);	
  		transform: translateY(-50%);
  		transition: $slow;
  		transition-delay: .4s;
  		z-index: 1;
  		text-align: center;
    	.join-headline,
    	.join-blurb {
    		transition: none !important;
    		transition-delay: 0 !important;
    	}		
    	.join-blurb {
    		margin-bottom: 0;
    	}	    	
  	}  			
	}
	&-headline {
		color: $white;
		margin-bottom: 0;
		transition: $bounce; 
		transition-delay: .2s !important;
		@extend %slide-up;
		@extend %headline2;
	}		
	&-blurb {
		font-family: $body;			
		color: $white;
		display: block;
		font-size: 1.1rem;
		line-height: 1.1;
		transition: $bounce; 
		margin: .25rem auto;
		@extend %slide-up;
		transition-delay: .4s !important;
		@include tablet {
			font-size: 1rem;
		}		
		@include desktop {
			font-size: 1.3rem;
		}					
	}	
	&-form {
		width: 100%;
		max-width: 50rem;
		display: flex;
		flex-wrap: nowrap;
		flex-direction: row;
		align-items: stretch;
		align-content: stretch;	
		margin: .75rem 0 .75rem -.5rem;
		@extend %slide-up;
		transition-delay: .6s !important;		
		@include tablet {
			margin: 1rem 0 .75rem -.5rem;
		}	
		@include desktop {
			margin: 1.5rem 0 .75rem -.5rem;
		}		
	}
	&-field {
		padding: 0 .25rem;
		flex: auto;
		position: relative;
		@include desktop {
			padding: 0 .3rem;
		}
		@include md {
			padding: 0 .35rem;
		}		
		@include lg {
			padding: 0 .5rem;
		}			
  	div.error {
 	  background: $red;
 	  padding: .15rem;
 	  font-size: 75%;
 	  color: $white;
 	  display: inline-block;
  		@include absolute(top 0 right .25rem);	
  		transform: translateY(-50%);
  		border: 1px solid $white;
  		@include desktop {
    		@include absolute(top 0 right .5rem);	
  		}  		
  	} 		
	}
	&-field.email {
		flex: 0 0 45%;
		@include tablet {
			flex: 0 0 45%;
		}	
		@include desktop {
			flex: 0 0 42%;
		}			
		@include md {
			flex: 0 0 50%;
		}				
	}	
	&-label {
		@include screen-reader-text;
	}	
 &-label-show {
  /**
    clip: auto;
    position: relative !important;
    height: auto;
    width: auto;
    overflow: visible;
    color: $white;
    text-align: left;
    cursor: pointer;
    display: block;
    margin-bottom: .25rem;
    **/
	}	
	&-input {
		background: transparent;
		padding: .65rem;
		border: 1px solid $white;
		width: 100%;
		color: $white;	
		font-size: 1.15rem;	
		outline: 0;
		transition: $slow;
		@include tablet {
			padding: .75rem;
			font-size: 1rem;
		}		
		@include desktop {
			padding: 1rem;			
			font-size: 1.2rem;
		}					
		@include md {
			font-size: 1.3rem;
		}	
		@include lg {
			font-size: 1.4rem;
		}	
		@include xl {
			font-size: 1.5rem;
		}			
		@include placeholder {
			color: $white;
			transition: $slow;
		}
		&-small-placeholder {
  		@include tablet {
  			padding: .65rem;
  		}		
  		@include desktop {
  			padding: .75rem .65rem;			
  		}					
  		@include placeholder {
     		font-size: 1.05rem;	
     		@include tablet {
     			font-size: 1rem;
     		}		
     		@include desktop {
     			font-size: 1.1rem;
     		}					
     		@include md {
     			font-size: 1.15rem;
     		}	
     		@include lg {
     			font-size: 1.2rem;
     		}	
     		@include xl {
     			font-size: 1.25rem;
     		}			
  		}
		}
		&:hover {
			background: darken($primary, 20%);
			@include placeholder {
				color: $white;
			}
		}
		&:focus {
			background: $white;
			color: $secondary;
			border-color: $white;
    	box-shadow: 0 0 0 4px $green;
			@include placeholder {
				color: $darkgreen;
			}
		} 			
	}	
	&-button {
		background: $white;
		color: $darkgreen;
		padding: none;
		cursor: pointer;
		border: 0;
		font-family: $headline;
		padding: .1rem .65rem 0;
		margin-left: .25rem;
		font-size: 1rem;
		text-transform: uppercase;
		white-space: nowrap;
		transition: $slow;
		&:hover {
			background: darken($darkgreen, 10%);	
			color: $white;
		}
		&:focus {
    	box-shadow: 0 0 0 4px $green;
		}
		@include tablet {
			font-size: .75rem;			
		}				
		@include desktop {
			font-size: 1rem;			
			padding: .1rem 1rem 0;
			margin-left: .5rem;
		}		
	}		
	&-disclaimer {
		font-size: .7rem;
		line-height: 1;
		display: block;
		@extend %slide-up;
		transition-delay: .8s !important;		
		@include tablet {
			font-size: .7rem;			
		}
		@include desktop {
			font-size: .8rem;	
			line-height: 1.15rem
		}			
		a {
			color: $white;
		}
	}	
	&-phone {
		width: 22vw;
		max-width: 19rem;
		@include absolute(left 50% bottom 0);
		transform: translateX(-50%);
		display: none;
		overflow: hidden;
		@include tablet {
			display: inline-block;
		}
		@include desktop {
			width: 16vw;
			transform: translateX(-55%);
		}		
		&-img {
			width: 90%;
			vertical-align: bottom;
			transform: rotate(5deg);
			@include relative(top 20rem);
			transition-delay: 1s !important;
			opacity: 0;
			transition: $bounce;
		}
		&.reveal {
			.join-phone-img {
				transform: rotate(-5deg);
				@include relative(top 1.5rem);
				opacity: 1;
			}
		}
	}
	&-large-label {
	 @extend %headline6;
	 color: $white;
	 margin-bottom: 1rem;
	 margin-top: 1.5rem;
	 text-transform: uppercase;
	 @include desktop {
 	  margin-top: 2.5rem;
	 }
	}
}
.espanol {
 .join {
   &-input {
    		font-size: 1.15rem;	
    		outline: 0;
    		transition: $slow;
    		@include tablet {
    			font-size: 1rem;
    		}		
    		@include desktop {
    			font-size: 1.1rem;
    		}					
    		@include md {
    			font-size: 1.1rem;
    		}	
    		@include lg {
    			font-size: 1.1rem;
    		}	
    		@include xl {
    			font-size: 1.1rem;
    		}		
   }
 }
}
