%footer-link {
	padding: .2rem;
	color: $gray;
	transition: $basic;
	margin: 0 auto;
	&:hover {
		color: $primary;
	}
	&:focus {
	  color: darken($primary, 10%);
  	box-shadow: 0 0 0 1px $green;
	}	  	
	@include tablet {
		font-size: .8rem;
	}
	@include desktop {
		font-size: 1rem;
	}	
}
.site-footer {
	background: $lightgray;
	@extend %block-padding;
	&-grid {
		margin: 0 auto;
		@include tablet {
			display: flex;
		}	
		@include desktop {
			display: flex;
			max-width: $max-width;
		}			
	}
	&-col {
		text-align: center;
		margin: 0 auto;
		@include tablet {
			text-align: left;
			flex: 0 0 23%;	
		}		
		&.copyright {
			text-align: center;
			font-size: .9rem;
			margin-top: 2rem;
			@include tablet {
				margin-top: 0;
				flex: 0 0 31%;					
			}
		}
		&:nth-child(1) {
			font-family: $headline;
		}		
	}	
	&-nav {
		text-align: left;
		&-grouping {
			@include phone {
				margin-top: 1rem;
			}			
			@include tablet {
				&:not(:first-child) {
					margin-top: 1rem;
				}
			}
		}
		&-link {
			@extend %footer-link;
			display: table;
			margin: 0 auto;
			@include tablet {
				margin: 0;
			}
		}
		&-header {
			font-family: $headline;
			color: $gray;
			text-transform: uppercase;
			transition: $basic;
			&:hover {
				color: $secondary;
			}
		}
	}
	.social {
		margin: 1.5rem 0 1.5rem 0;
		@include tablet {
			margin-bottom: 0;
			margin: 1rem 0 1rem 0;			
		}
		@include desktop {
			margin: 1.5rem 0 1.5rem 0;			
		}				
		&-link {
			@include size(1.75rem, 1.75rem);
			@include tablet {
				@include size(1.35rem, 1.35rem);				
			}	
			@include desktop {
				@include size(1.75rem, 1.75rem);
			}				
		}
	}
}
.paidfor {
	padding: .75rem;
	line-height: 1.3;
	border: 1px solid rgba($gray,.4);
	margin-bottom: 1.25rem;
	@include tablet {
		font-size: .7rem;
	}	
	@include desktop {
		font-size: 1rem;
	}		
}
.copyright {
	&-content {
		margin-bottom: 1rem;
		@include tablet {
			font-size: .8rem;
		}	
		@include desktop {
			font-size: 1rem;
		}			
	}
	&-nav {
		&-link {
			@extend %footer-link;
			display: inline-block;
		}
	}
}
