%expanded-menu-link {
	padding: .5rem 0;
	color: $white;
	transition: $veryslow;
	display: inline-block;
	@include tablet {
		padding: .25rem .4rem;
		font-size: 1rem;
	}
	@include desktop {
		font-size: 1.2rem;
	}		
	&:hover {
		background: $darkgreen;
		color: $white;
	}
	&:focus {
  	box-shadow:  0 0 0 2px $darkgreen;
	}
}
.expanded-menu {
	background: $darkgreen;
	@include size(87vw, 100vh);
	@include fixed(top 0 right 0);
	z-index: 100000;	
	transition: $slow;
	transform: translateX(100%);
	visibility: hidden;		
	box-shadow: -.5rem 0 1.5rem 0 rgba(0,0,0,.25);
	overflow-y: scroll;
	@include edge {
  	overflow: hidden;
	}		
	@include tablet {
		@include size(100%);
		@include fixed(top 0 left 0);
		background: $green;
		padding: 0;
		opacity: 0;
		padding: 1rem;
		transform: translateX(0);		
	}
	@include desktop {
		padding: 2rem;
	}	
	&-grid {
		margin: 0 auto;
		@include tablet {
			width: 48em;
			max-width: 90%;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translateX(-50%) translateY(-50%);
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-content: flex-start;
			align-items: stretch;			
		}	
		@include tablet {
			width: 64em;
		}			
	}
	&-col {
		margin: 0;
		text-align: left;
		@include tablet {
			opacity: 0;
			transform: translateY(3rem);			
			transition: $slow;	
			flex: 1 1 auto;
		}
		&:last-child {
			font-family: $headline;
			@include tablet {
				padding: 1.5rem;
				background: $darkgreen;				
			}
			@include desktop {			
				padding: 2rem;
			}			
			.expanded-menu-section {
				background: $darkgreen;
			}
			.expanded-menu-nav-link {
				color: $white;
				display: block;
				padding: .65rem .5rem;
				transition: $slow;
				font-size: 1rem;
				@include tablet {
					font-size: 1rem;
				}
				@include desktop {
					font-size: 1.3rem;
				}				
				&:hover {
					color: $primary;
				}
				&:focus {
        	box-shadow: 0 0 0 2px $green;
				}
			}
		}		

	}	
	&-section {
		background: $primary;
		text-align: left;
		padding: 1rem 1rem;
		@include tablet {
			background: transparent;
			padding: 0;
			margin-bottom: 1rem;
			&:last-of-type {
				margin-bottom: 0;
			}
		}
		&-header {
			display: block;
			position: relative;
			@include tablet {
				margin-bottom: 0rem;
			}			
			&-link {
				font-family: $headline;
				color: $white;
				text-transform: uppercase;
				transition: $basic;		
				font-size: 1rem;
				@include tablet {
					font-size: 1rem;	
					padding: .2rem .4rem;
				}
				@include desktop {
					font-size: 1.3rem;					
				}				
				&:hover {
					color: $darkgreen;
				}
      	&:focus {
        	box-shadow:  0 0 0 2px $darkgreen;
      	}				
			}
			&-arrow {
				@include size(1rem, 1rem);
				@include absolute(top 50% right .5rem);	
				transform: translateY(-50%);
				display: block;
				transition: $slow;
				@include tablet {
					display: none;
				}
				&-svg {
					fill: $white;
					width: 100%;
					display: block;
					transition: $bounce;
				}
			}
		}	
	}
	&-dropdown {
		display: none;
		padding-top: .5rem;
		@include tablet {
			display: block;
		}
		&-link {
			@extend %expanded-menu-link;
			display: table;

		}
	}	
	&-grouping {
		&:not(:first-child) {
			margin-top: .5rem;
		}
	}	
	.social {
		margin-left: 1rem;
		@include tablet {
			margin: 1rem auto 0;
		}		
		&-link {
			@include size(2.5rem);
			padding: .5rem;
			@include tablet {
				@include size(1.75rem);
				padding: .25rem;				
			}		
			@include desktop {
				@include size(2.5rem);
				padding: .5rem;
			}				
		}
		&-icon {
			fill: $white;
			&:hover {
				fill: $primary
			}
		}
	}
	&-search {
		flex: 0 0 100%;
		@include tablet {
			opacity: 0;
			transform: translateY(3rem);			
			transition: $slow;	
			padding-bottom: 2rem;			
		}		
		@include desktop {
			padding-bottom: 2rem;
		}	
		@include ie {
			flex: 0 0 100%;
		}		
		@keyframes border-pulse {
		  from { border: 1px solid $white; }
		  50%  { border: 1px solid $darkgreen; }
		  to   { border: 1px solid $white; }
		}		
		&-form {
			background: $primary;
			width: 100%;
			display: flex;
			padding: 0 0;
			transition: $slow;
			cursor: pointer;
			@include tablet {
				background: transparent;
				border: 1px solid rgba($white, .3);
			}				
			&:hover {
				background: $darkgreen;
				border: 1px solid rgba($white, .7);				
			}	
			&.search-active {
				background: $darkgreen;
				animation-name: border-pulse;
				animation-duration: 1s;		
				animation-iteration-count: infinite;
			}				
		}
		&-label {
			@include screen-reader-text;
		}
		&-field {
			background: transparent;
			padding: 1rem;
			font-size: 1.3rem;
			border: 0;
			width: 100%;
			color: $white;		
			transition: $slow;
			@include placeholder {
				color: $white;
				transition: $slow;
			}
			&:hover {
				@include placeholder {
					color: $white;
				}
			}
			&:focus {
				@include placeholder {
					color: $white;
				}
			} 
		}	
		&-button {
			flex: 0 0 3rem;			
			border: 0;
			background: transparent;
			cursor: pointer;
			transition: $slow;
			padding: 1.1rem 1rem 1rem;
			&-svg {
				width: 100%;
				max-height: 1.5rem;
				fill: $white;
				transition: $slow;
			}
			&:hover {
				&-svg {
					fill: $darkgreen;
				}				
			}
			&:focus {
			  background: $darkgreen;
				&-svg {
					fill: $secondary;
				}					
			}			
		}		
	}
}
.mobile-menu-close {
	@include size(100%, 100%);	
	@include fixed(top 0 left 0);
	background: rgba($white, .7);
	display: block;
	z-index: 10000;
	opacity: 0;
	visibility: hidden;
	transition: $slow;
}
.display-menu {
	overflow: hidden;
	.expanded-menu {
		transform: translateX(0);
		visibility: visible;
		@include size(85vw, 100vh);
		@include tablet {
			@include size(100vw, 100vh);
			opacity: 1;
			visibility: visible;
		}
	}
	&.reveal-items {
		.expanded-menu {
			&-col,
			&-search {
				@include tablet {
					transition: all .6s cubic-bezier(.5,1.65,.4,.8);	
					opacity: 1;
					transform: translateY(0);					
				}
			}
			&-col {
				@include tablet {
					&:nth-child(2){
						transition-delay: .2s !important;
					}	
					&:nth-child(3){
						transition-delay: .4s !important;
					}				
					&:last-child {
						transition-delay: .6s !important;
					}			
				}	
			}
		}
	}
	.mobile-menu-close {
		opacity: .6;
		visibility: visible;
	}	
}
.expanded-menu-section-header.expanded  {
	.expanded-menu-section-header-link {
		color: $white;
	}	
	.expanded-menu-section-header-arrow-svg {
		transform: rotate(180deg);
	}
}	
