.photo-promo {
 background-color: $gray;
	box-shadow: $shadow;
	position: relative;
	opacity: 0;
	transition: $slow;
	overflow: hidden;
	
	&.add_top_padding {
  	margin-top: 1.5rem;
  	@include tablet { 
  		margin-top: 2rem;		
  	}
  	@include desktop {
  		margin-top: 4rem;		
  	}
  	@include md { 
  		margin-top: 5rem;				
  	}	
  	@include lg { 
  		margin-top: 6rem;						
  	}
  	@include xl { 
  		margin-top: 7rem;								
  	}		
	}
	&.no_top_margin {
	  margin-top: 0 !important;
	}
	&.not_full_width {
	 max-width: $max-width;
		margin: 1rem 1rem 0;
		@include tablet {
  		margin: 2rem auto;
		}
	}
	&.reveal {
		opacity: 1;
		.photo-promo-title {
			@extend %slide-up-reveal;
		}
	}
 &.add_gutter {
		 background: transparent;
		 box-shadow: none;
 }	
	&-title {
		font-family: $headline;
		margin: 1rem auto;
		@extend %slide-up;
		color: $white;
		@extend %headline2;
		@include desktop {
			margin: 2rem auto;			
		}			
		&-link {
			color: $white;
			transition: $slow;
			padding: 0 .5rem;
			&:hover {
				color: $primary;
			}
		}
	}
	&-grid {
		width: 100%;
		@include tablet {
			display: flex;
			flex-wrap: nowrap;
			flex-direction: row;
			align-items: stretch;
			align-content: stretch;
		}
  &.add_gutter {
   		 @include tablet {
   		    padding: 1rem;
         gap: 1rem;

   		 }
   		 @include md {
   		    padding: 1.5rem;
         gap: 1.5rem;
   		 }
  }			
	}
	&-video-item {
	 .photo-promo-item-center {
  	  align-items: flex-end;
	 }
	}
	&-item {
		position: relative;
		overflow: hidden;
		z-index: 100;
		@include tablet {
		 flex: 1;
			transition-delay: .1s;
			&:nth-child(2) {
				transition-delay: .2s;
			}
			&:nth-child(3) {
				transition-delay: .3s;
			}	
		}
		@include md {
   flex: 1;
		}
		&.green {
			background: $primary;
		}
		&.blue {
			background: $secondary;
		}	
		&.dark-green {
			background: darken($primary, 20%);
		}
		&.dark-blue {
			background: darken($secondary, 20%);
		}			
		&.dark-gray {
			background: darken($darkgray, 20%);
		}		
		&.gray {
			background: $gray;
		}		
		&.black {
			background: $black;
		}				
		&.white {
			background: $white;
			.photo-promo-item {
				&-headline,
				&-blurb {
					color: $gray;
				}					
			}			
		}			
		&-aspect {
			@include aspect(16,9);
			@include size(100%);
		}					
		&-center {
			@include size(100%, 100%);
			@include absolute(top 0 left 0);	
			z-index: 400;
			align-items: center;
			display: flex;
			justify-content: center;
			overflow: hidden;	
			padding: 1.5rem;
			@include tablet {
				padding: 3rem 1rem;
			}		
			@include desktop {
				padding: 4rem 2rem;
			}
			@include md {
				padding: 4.5rem 2rem;
			}
			@include lg {
				padding: 5rem 2rem;
			}			
			@include xl {
				padding: 6rem 2rem;
			}						
  		&:focus {
      	box-shadow: inset 0 0 0 8px $green;
  		}			
		}
		&-text {
			transition: $bounce;
			z-index: 100;
			position: relative;
			@extend %slide-up;			
			@include desktop {
				max-width: $tablet;
				margin: 0 auto;
			}				
			p, ul, ol {
			  margin-bottom: 1rem;
			  &:last-of-type {
			    margin-bottom: 0;
			  }
			}
			&.left_justify_text {
			  text-align: left;
			}
		}	
		&-link {
			z-index: 100;
			position: relative;
			display: block;
			margin: 0 auto;
			padding: 2rem 0;
			@include tablet {
				padding: 2rem 0;
			}		
			@include desktop {
				padding: 3rem 0;
			}	
			
			@include lg {
				padding: 4rem 0;
			}					
		}		
  &-branding {
    width: 50%;
    max-width: 22rem;
    min-height: 5rem;
  }		
		&-headline {
			font-family: $headline;			
			color: $white;
			transition: $bounce;
			@extend %headline3;
		}	
		&-subheadline {
			font-family: $headline;			
			color: $white;
			transition: $bounce;
			margin-top: 1rem;
			display: block;
			@extend %headline5;
			@include tablet {
				margin-top: 1.25rem;
			}		
			@include desktop {
				margin-top: 1.5rem;
			}					
		}				
		&-blurb {
			font-family: $body;			
			color: $white;
			display: block;
			transition-delay: .4s !important;
			margin: .75rem auto;
			font-size: 1rem;
			line-height: 1.3;
			text-align: center;
			@include tablet {
				margin: .75rem auto;				
			}		
			@include lg {
				margin: 1rem auto;				
				font-size: 1.3rem;
			}					
			p, ul, ol {
			  margin-bottom: 1rem;
			}
			p {
			 &:last-of-type {
			  margin-bottom: 0rem;
			 }
			}
			&.left_justify_text {
			  text-align: left;
			}			
		}		
		&-photo {
			@include size(100%, 100%);
			@include absolute(top 0 left 0);	
			z-index: 1;
			background-repeat: no-repeat;  
			background-position: center center;
			background-size: cover;  		
			transition: $slow; 
			opacity: .5;
			transform: scale(1.2);			
		}
		&:hover {
			.photo-promo {
				&-item {
					&-headline {}
					&-photo {
						opacity: .15 !important;
						transform: scale(1.25) !important;
					}					
				}
			}
		}	
		&.square {
			.photo-promo-item-aspect {
				@include aspect(6,5);
			}			
		}
		&.letterbox {
			.photo-promo-item-aspect {
				@include aspect(16,9);
			}			
		}
		&.flex-with-content {
			.photo-promo-item {
				&-aspect {
					padding-top: 0 !important;
				}
				&-center {
					@include relative(top 0 left 0);	
					@include phone {
					  padding-top: 3rem;
					  padding-bottom: 3rem;
					}
				}
			}			
		}	
		&.match-sibling {
			.photo-promo-item-center {
				@include phone {
					@include relative(top 0 left 0);	
				}
			}			
		}				
		&.wide {
			@include tablet {
				flex: 0 0 66%;
			}
		}
		.button {
			&-block {
			  margin-top: 1rem;
				transition-delay: 2s;
			}
		}			
		&.reveal {
			.photo-promo-item {
				&-text {
					@extend %slide-up-reveal;
				}					
				&-photo {
					opacity: .4;
					transform: scale(1);
					&.no_dim {
					  opacity: 1;
					}
				}
			}
			.button {
				&-block {
					@extend %slide-up-reveal;
				}
			}
		}
		&.multi-button {
  		&:hover {
  			.photo-promo {
  				&-item {
  					&-headline {
  						transform: scale(1) !important;
  					}		
  					&-photo {
  						opacity: .4 !important;
  						transform: scale(1) !important;
    					&.no_dim {
    					  opacity: 1;
    					}      						
  					}					
  				}
  			}
  		}	
    }
	}
}

.photo-promo-grid {
		&.grid3367 {
		   background: $blue;
		  .photo-promo-item {
		     &:first-child {
		       @include desktop {
   		       width: 33%;
   		      	flex: unset;
		       }	
		     }
		    &:last-child {
		       @include desktop {
   		       width: 67%;
		       }		     
		    }		     
		  }		 
		}
		&.grid6733 {
		  .photo-promo-item {
		     &:last-child {
		       @include desktop {
   		       width: 33%;
   		      	flex: unset;
		       }		    		      	
		     }
		    &:first-child {
		       @include desktop {
   		       width: 67%;
		       }		     
		    }
		  }
		}
}

.text-layer {
  .photo-promo-item {
   &-center {
     display: flex;
     flex-wrap: wrap;
     flex-direction: row;
     justify-content: center;
     align-items: flex-end;
     align-content: flex-end
   }
   &-text {
    position: absolute;
    bottom: 1rem;
   }
   .photo-promo-item-headline {
      font-size: 1.3rem;
      color: $white;
      margin: 0 auto;
      text-transform: uppercase;
      text-align: center;
      white-space: nowrap;
   }
   .photo-promo-item-subheadline {
      margin-top: 0;
      color: $white;
      font-size: 1rem;
      text-align: center;
   }
   .button {
      padding: .75rem 1.5rem;
      &-block {
         margin-top: 0;
      }
   }
  }
}
