%utlility-nav-text {
	font-family: $body;		
	font-size: .85rem;
	display: inline-block;
	text-decoration: none;
	color: lighten($gray, 25%);
	transition: $basic;
	cursor: pointer;	
	text-transform: uppercase;
	position: relative;
	z-index: 100;	
	outline: 0;
	&:hover,
	&:focus { 
		color: $primary;
	}
	&:focus { 
		box-shadow: inset 0 0 0 2px $primary;
	}
}
%utlility-nav {
	position: absolute;
	top: 0;
	display: none;
	transition: opacity .5s cubic-bezier(.57,.2,.21,.89);
	transition-delay: .3s !important;
	@include desktop {
		display: inline-block;
	}
}
.utility-nav {
	&-item {
		padding: .75rem .5rem;
		@extend %utlility-nav-text;
		&.active {
		  color: $primary;
		}
		span {
		  text-transform: none;
		}
		&.hide-small {
		  display: none;
		  @include md {
		    display: inline-block;
		  }
		}
	}
}
.utility-left {
	@extend %utlility-nav;
	left: 0;
	padding: .5rem .5rem .75rem;
}
.utility-right {
	@extend %utlility-nav;	
	right: 0;	
}
.search-trigger {
	background: transparent;
	border: 0;
	@extend %utlility-nav-text;
	padding: .75rem;
	transform-origin: right top;
	@include fixed(top 0 right 0);	
	z-index: 10000;
	display: inline-block;
	trasition: none;
	@include tablet {
		z-index: 200000;
	}		
	&-label {
	}
	&-icon {
		width: .75rem;
		max-height: .75rem;
		margin-left: .15rem;
		fill: lighten($gray, 25%);
		transition: $basic;
		position: relative;
		top: .05rem;
	}
	&:hover {
		.search-trigger {
			&-label {
				color: $primary;
				transition: $basic;
			}			
			&-icon {
				fill: $primary;
				transition: $basic;
			}
		}
	}
	&:focus {
		.search-trigger {
			box-shadow: inset 0 0 0 2px $primary;
			&-label {
				color: $primary;
			}			
			&-icon {
				fill: $primary;
			}
		}
	}

}
.fixed { 
	.utility-left,
	.utility-right {
		transition-delay: .0s !important;
		opacity: 0;
		visibility: hidden;
	}
	.search-trigger-label {
		opacity: 0;
	}	
}
.display-menu {
	.search-trigger {
		opacity: 0;
		visibility: hidden;
	}
}
.search-trigger.fixed-menu {
	transform: scale(1.5) translateY(-50%);

}	
