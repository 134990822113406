.news-card {
	position: relative;
	padding: .5rem 0rem;
	@extend %slide-up;	
	&.reveal {
		@extend %slide-up-reveal;	
	}
	@include tablet {
		width: 50%;
		padding: .5rem;
		&:nth-child(1) {
		  padding-top: 0;
		}
		&:nth-child(2) {
		  padding-top: 0;
			transition-delay: .2s;
		}
		&:nth-child(3) {
			transition-delay: 0s;
		}	
		&:nth-child(4) {
			transition-delay: .2s;
		}			
	}	
	@include tablet {
		padding: .75rem;
	}
	@include desktop {
		padding: 1rem;
	}
	@include md {
		padding: 1.5rem;
	}	
	@include lg {
		padding: 1.75rem;
	}	
	@include xl {
		padding: 2rem;
	}		
	&-link {
		@include absolute(top 0 left 0);	
		@include size(100%);
		z-index: 200;
		display: block;
  	&:focus {
    	box-shadow: inset 0 0 0 6px $green;
    	.news-card {
    	  &-text {
    	    &:before {
    	      display: block;
    	    }
    	  }
    	}
  	}		
	}
	&-aspect {
		background: $gray;
		@include aspect(5,6.5);
		position: relative;
		overflow: hidden;
		box-shadow: $shadow;
		transition: $slow;
		@include tablet {
			@include aspect(5,6.5);
		}
		@include desktop {
			@include aspect(5,6.5);
		}			
	}
	&-section-label {
		@include absolute(top 0 left 0);	
		z-index: 300;
		display: inline-block;
		color: $white;
		text-transform: uppercase;
		transition: $slow;	
		font-family: $headline;
		font-size: .9rem;
		padding: .65rem .85rem;
		&:hover {
			background: $primary;
			color: $white;
		}
		@include tablet {
			font-size: .75rem;						
		}		
		@include desktop {
			font-size: .9rem;			
		}
	}
	&-text {
		background: linear-gradient(0deg, rgba($gray,1) 75%, rgba($gray,0) 100%);
		padding: 1rem 1rem;
		@include size(100%, auto);
		@include absolute(bottom 0 left 0);	
		z-index: 200;
		text-align: left;
		@include tablet {
			padding: 3rem 1rem 1rem;	
  		min-height: 8rem;
		}		
		@include desktop {
			padding: 4rem 2rem 2rem;		
		  min-height: 10rem;
		}		
		&:before {
		  content: "";
		  display: none;
  		@include size(100%);
  		@include absolute(bottom 0 left 0);
  		border: 6px solid $green;
  		border-top: 0;
		}
		&:focus {
		  
		}
	}
	&-date {
		color: $white;
		display: block;
		font-size: .7rem;
		text-transform: uppercase;
		margin: .5rem auto;				
		@include tablet {
			font-size: .65rem;
		}		
		@include desktop {
			font-size: .9rem;
		}					
	}	
	&-headline {
		color: $white !important;
		margin-bottom: 0;
		@extend %headline5;
	}
	&-blurb {
		color: $white;
		display: block;
		margin: .75rem auto;
		line-height: 1.2;
		@extend %basic-text;
		font-size: 100%;
		display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
		@include tablet {
		}		
		@include desktop {
			margin: .5rem auto;				
		}					
	}	
	&-more {
		color: $white;
		text-transform: uppercase;
		display: block;
		transition: $slow;	
		font-family: $headline;
		margin-top: .25rem;
		font-size: .9rem;
		position: relative;
		@include tablet {
			font-size: .7rem;
		}		
		@include desktop {
			font-size: .9rem;
		}			
		&-arrow {
			width: .35rem;
			max-height: .5rem;
			margin-left: .35rem;
			fill: $white;
			transition: $slow;
			@include relative(top .1rem left 0);				
		}
		&:hover {
			color: $primary;
			&-arrow {
				fill: $primary;
			}
		}
	}	
	&-photo {
		@include size(100%, 80%);
		@include absolute(top 0 left 0);				
		background-repeat: no-repeat;  
		background-position: center center;
		background-size: cover;  		
		z-index: 100;
		transition: $veryslow; 
		transform: scale(1);			
	}
	&-play-button {
		@include absolute(top 25% left 50%);				
    transform: translateX(-50%);	  
		background: rgba($black, .3);
		z-index: 250;
		@include size(5rem);
		border-radius: 50%;
		padding: 1.5rem 1.3rem 1.5rem 1.7rem;
		margin: 0 auto 1rem;
		transition: $slow;
		&-icon {
			width: 100%;
			fill: $white;
			transition: $slow;
			position: relative;
			left: .2rem;
		}
	}
	&.video-card {
	   .news-card {
        &-text {
          min-height: auto;
        }
        &-photo {
		      @include size(100%, 95%);
        }
       	&-headline {
      		@extend %headline6;
      		margin-bottom: 0;
      	}
	   }
	}
	&:hover {
		.news-card {
		  &-aspect {
		    background-color: darken($gray, 20%);
		  }
			&-photo {
				transform: scale(1.2);			
			}	
    	&-play-button {
        transform: translateX(-50%) scale(1.1);	  
    		background: rgba($white, 1);
    		&-icon {
    			fill: $primary;
    		}
    	}			
		}
	}		
}
.page-jerry-wurf-memorial-fund {
  .news-card {
    &:last-of-type { display: none; }
  }
}
