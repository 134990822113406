.featured-action {
    position: relative;
    margin-bottom: 1rem;
    @extend %slide-up;
    @include tablet {
        margin-bottom: 1rem;
    }
    @include desktop {
        margin-bottom: 2rem;
    }
    &.green {
      .featured-action {
         &-aspect {
            background: $green;
         }
         &-text {
            background: linear-gradient(to bottom, rgba($green, 1) 0%, rgba($green, 1) 30%, rgba($green, 0) 45%, rgba($green, 0) 0%);
         }
      }
    }    
    &.dark-green {
      .featured-action {
         &-aspect {
            background: $darkgreen;
         }
         &-text {
            background: linear-gradient(to bottom, rgba($darkgreen, 1) 0%, rgba($darkgreen, 1) 30%, rgba($darkgreen, 0) 45%, rgba($darkgreen, 0) 0%);
         }
      }
    }    
    &.blue {
      .featured-action {
         &-aspect {
            background: $blue;
         }
         &-text {
            background: linear-gradient(to bottom, rgba($blue, 1) 0%, rgba($blue, 1) 30%, rgba($blue, 0) 45%, rgba($blue, 0) 0%);
         }
      }
    }    
    &.dark-blue {
      .featured-action {
         &-aspect {
            background: $darkblue;
         }
         &-text {
            background: linear-gradient(to bottom, rgba($darkblue, 1) 0%, rgba($darkblue, 1) 30%, rgba($darkblue, 0) 45%, rgba($darkblue, 0) 0%);
         }
      }
    }
    &.reveal {
        @extend %slide-up-reveal;
    }
    .button {
        margin: 0;
        &-text {
            color: $secondary !important;
        }
        &:before,
        &:after {
            display: none;
        }
        &-block {
            opacity: 1;
            transform: translateY(0);
            margin-top: 1em;
            text-align: left;
        }
        &-text {
            @include tablet {
                font-size: .7rem;
            }
            @include desktop {
                font-size: .8rem;
            }
        }
    }

    @include tablet {
        &:nth-child(2) {
            transition-delay: .2s;
        }
        &:nth-child(3) {
            transition-delay: 0s;
        }
        &:nth-child(4) {
            transition-delay: .2s;
        }
    }
    &-aspect {
        background: $gray;
        @include aspect(5, 8);
        position: relative;
        overflow: hidden;
        box-shadow: $shadow;
        @include tablet {
            @include aspect(5, 7.5);
        }
        @include desktop {
            @include aspect(5, 7);
        }
    }

    &-link {
        @include absolute(top 0 left 0);
        @include size(100%);
        display: block;
        &:focus {
            .featured-action {
                &-text {
                    &:before {
                        display: block;
                    }
                }
                &-photo {
                    &:before {
                        display: block;
                    }
                }
            }
        }
    }

    &-section-label {
        z-index: 300;
        display: table;
        color: $white;
        text-transform: uppercase;
        transition: $slow;
        margin-bottom: .75rem;
        @extend %headline7;
        @include tablet {
            margin-bottom: .5rem;
        }
        @include desktop {
            margin-bottom: .75rem;
        }
    }

    &-text {
        background: linear-gradient(to bottom, rgba($black, 1) 0%, rgba($black, 1) 30%, rgba($black, 0) 45%, rgba($black, 0) 0%);
        padding: 2rem 2rem 3rem;
        @include size(100%);
        @include absolute(top 0 left 0);
        z-index: 200;
        text-align: left;
        @include tablet {
            padding: 1rem 1rem 2rem;
        }
        @include desktop {
            padding: 2rem 2rem 3rem;
        }
        &:before {
            content: "";
            display: none;
            @include size(100%);
            @include absolute(bottom 0 left 0);
            border: 6px solid $green;
        }
    }

    &-headline {
        font-family: $headline;
        color: $white;
        margin-bottom: 0;
        @extend %headline4;
    }

    &-blurb {
        color: $white;
        display: block;
        margin: .75rem auto;
        line-height: 1.3;
        @include tablet {
            font-size: 100%;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        @extend %basic-text;

        @include desktop {
            margin: .5rem auto;
            -webkit-line-clamp: 12;
        }
    }

    &-photo {
        @include size(100%, 80%);
        @include absolute(bottom 0 left 0);
        background-repeat: no-repeat;
        background-position: center top;
        background-size: cover;
        z-index: 100;
        transition: $veryslow;

        &:before {
            content: "";
            display: none;
            @include size(100%);
            @include absolute(bottom 0 left 0);
            border: 6px solid $green;
            border-bottom: 0;
        }
    }

    &:hover {
        .featured-action {
            &-photo {
                transform: scale(1.2);
            }
        }
    }
}
