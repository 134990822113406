.benefits {
	@extend %block-padding;
	text-align: center;
	max-width: $max-width;
	margin: 0 auto;
	&.reveal {
		display: block;
		.benefits {
			&-headline,
			&-blurb {
				@extend %slide-up-reveal;
			}
		}
	}	
	&-headline {
		font-family: $headline;			
		color: $gray;
		font-size: 1.4rem;
		margin-bottom: 0;
		transition: $bounce; 
		transition-delay: .2s !important;
		@extend %slide-up;		
		@include tablet {
			font-size: 1.6rem;
		}		
		@include desktop {
			font-size: 2.5rem;
		}					
	}	
	&-blurb {
		font-family: $body;			
		color: $gray;
		display: block;
		font-size: 1rem;
		line-height: 1.3;
		transition: $bounce; 
		margin: .25rem auto;
		@extend %slide-up;				
		transition-delay: .4s !important;
		@include tablet {
			font-size: 1rem;
		}		
		@include desktop {
			font-size: 1.3rem;
			max-width: $tablet;
		}					
	}		
	&-grid {
		width: 100%;
		margin: 1rem auto;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		@include tablet {
			margin: 2rem auto;
			flex-wrap: nowrap;
			flex-direction: row;
			align-items: stretch;
			align-content: stretch;
		}
		@include desktop {
			margin: 3rem auto;
		}		
	}	
	&-item {
		padding: 1rem 2rem;
		width: 50%;
		@extend %slide-up;	
		@include tablet {
			width: 20%;
			padding: 1rem 2.5rem;
			&:nth-child(2) {
				transition-delay: .1s !important;		
			}
			&:nth-child(3) {
				transition-delay: .2s !important;		
			}
			&:nth-child(4) {
				transition-delay: .3s !important;		
			}
			&:nth-child(5) {
				transition-delay: .4s !important;		
			}			
		}		
		@include desktop {
			padding: 1rem 3rem;			
		}		
		&-link {
			display: block;
			transition: $bounce;
			padding: .5rem;
  		&:focus {
      	box-shadow: 0 0 0 4px $green;
  		}			
		}
		&-icon {
			margin-bottom: 1rem;
		}	
		&-svg {
			fill: url(#icon-gradient) $primary;
			width: 100%;
			max-height: 8rem;
			filter: drop-shadow(.15rem .2rem .15rem rgba($black,.25));
		}		
		&-headline {
			color: $gray;
			text-transform: uppercase;
			font-family: $headline;	
			font-size: .75rem;					
			margin-top: .5rem;
			margin-bottom: .75rem;
			@include tablet {
				font-size: .8rem;		
			}		
			@include desktop {
				font-size: .9rem;		
			}				
		} 
		&:hover {
			.benefits-item {
				&-link {
					transform: scale(1.1);
				}				
				&-headline {
					color: $primary !important;
				}
			}
		}		
		&.reveal {
			@extend %slide-up-reveal;
		}
	}
}
