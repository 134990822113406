.difference {
	@extend %block-padding;
	text-align: center;
	max-width: $max-width;
	margin: 0 auto;
	&.reveal {
		display: block;
		.difference {
			&-headline,
			&-blurb {
				@extend %slide-up-reveal;
			}
		}
	}			
	&-grid {
		width: 100%;
		margin: 1rem auto;
		@include tablet {
			margin: 2rem auto;
			display: flex;
			flex-wrap: nowrap;
			flex-direction: row;
			align-items: stretch;
			align-content: stretch;
		}
		@include desktop {
			margin: 3rem auto;
		}		
	}	
	&-headline {
		font-family: $headline;			
		color: $gray;
		font-size: 1.6rem;
		margin-bottom: 0;
		transition: $bounce; 
		transition-delay: .2s !important;
		@extend %slide-up;
		@include tablet {
			font-size: 1.6rem;
		}		
		@include desktop {
			font-size: 3rem;
		}					
	}		
	&-blurb {
		font-family: $body;			
		color: $gray;
		display: block;
		max-width: $tablet;
		font-size: 1rem;
		line-height: 1.3;
		transition: $bounce; 
		margin: .25rem auto;
		@extend %slide-up;
		transition-delay: .4s !important;
		@include tablet {
			font-size: 1.1rem;
		}		
		@include desktop {
			font-size: 1.3rem;
		}					
	}
	&-icon {
		&-svg {
			fill: $primary;
			width: 80%;
			max-height: 8rem;
			@include tablet {
				width: 50%;
  			min-height: 6rem;
			}		
			@include desktop {
				width: 80%;
  			max-height: 9rem;
			}	
			@include md {
				width: 100%;
  			min-height: none;
			}					
		}
	}
	&-item {
		padding: 1rem 1rem 3rem;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: center;
		&:last-of-type {
		  padding-bottom: 1rem;
		}
		@extend %slide-up;			
		@include tablet {
			padding: 1rem;
			&:nth-child(2) {
				transition-delay: .1s !important;		
			}
			&:nth-child(3) {
				transition-delay: .2s !important;		
			}
			&:nth-child(4) {
				transition-delay: .3s !important;		
			}
			&:nth-child(5) {
				transition-delay: .4s !important;		
			}			
		}		
		@include md {
			padding: 1rem 2rem;
		}			
		&-headline {
			color: darken($primary, 10%);
			text-transform: uppercase;
			font-family: $headline;	
			font-size: 1.2rem;
			margin-top: .5rem;
			margin-bottom: .75rem;
			transition-delay: .2s !important;
		} 
		&-blurb {
			transition-delay: .4s !important;			
		} 	
		&-stat {
		} 	
		&-number {
			color: darken($primary, 10%);
			font-family: $text;	
			font-size: 4.5rem;
			letter-spacing: -.2rem;
			position: relative;
			display: table;
			margin: 1rem auto 0;
			transition-delay: .4s !important;	
			transform: translateX(1.175rem);
			@include tablet {
				margin-top: 1.5rem;				
				font-size: 3.5rem;
			}		
			@include desktop {
				margin-top: 1.75rem;				
				font-size: 5rem;
			}				
		} 
		&-arrow {
			display: inline-block;
			@include absolute(left -2.75rem bottom 1rem);
			width: 2.35rem;
			height: 0%;
			transition: height 1s cubic-bezier(.57,.2,.21,.89);
			transition-delay: .5s;
			transform-origin: center bottom;
			@include phone {
				bottom: .75rem;
			}
			&-top {
				@include size(100%);				
				@include absolute(left 50% top 0);	
				transform: translateX(-50%);				
				max-height: 2rem;
				fill: darken($primary, 10%);
			}
			&-line {
				@include size(.45rem, 81%);
				@include absolute(left 50% bottom 0);
				background: darken($primary, 10%);
				display: block;
				transform: translateX(-50%);
			}
			&.reveal {
				height: 75%;
			}
		}
		&-details {
			transition-delay: .8s !important;						
		} 
		&.reveal {
			@extend %slide-up-reveal;
		}
	}
	.button {
		@include desktop {
			min-width: 14rem;
		}			
		&-block {
			margin-top: 2rem;
			@include tablet {
				margin-top: 2rem;
			}		
			@include desktop {
				margin-top: 4rem;
			}		
		}
	}			
}
