.h1 {
  @extend %headline1;
  color: $primary;
}
.h2 {
  @extend %headline2;
  color: $secondary;
}
.h3 {
  @extend %headline3;
  color: $gray;
}
.h4 {
  @extend %headline4;
  color: $gray;
}
.h5 {
  @extend %headline5;
  color: $gray;
}
.h6 {
  @extend %headline6;
  color: $gray;
}
