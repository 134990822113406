.more-simple {
  font-family: $headline;
	color: $gray;
  margin-top: .75rem;
  text-transform: uppercase;
  font-size: .9rem;
  display: block;
  transition: $slow;
	@include phone {
		text-align: center;
	}
	&-arrow {
		width: .35rem;
		max-height: .5rem;
		margin-left: .35rem;
		fill: $gray;
		transition: $slow;
	}
	&:hover {
		color: $secondary;
		&-arrow {
			fill: $secondary;
		}
	}
}	
.issues-item:hover {
  .more-simple {
		color: $secondary;
		&-arrow {
			fill: $secondary;
		}
  }
}
