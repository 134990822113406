.social {
	display: inline-block;
	vertical-align: middle;
	&-link {
		@include size(1.5rem);
		display: inline-block;
		padding: .25rem;
		&:focus {
			outline: 1px solid $primary;
			.social-icon {
				fill: $primary;	
			}
		}
	}
	&-icon {
		@include size(100%, 100%);
		fill: lighten($gray, 25%);
		transition: $basic;
		&:hover {
			fill: $primary;	
		}	
	}
}
