.leadership {
  &-latest {
  	background: $white;
  	margin-bottom: 1rem;
  	padding: 0;
  	@include tablet {
  	  padding: .15rem 1rem 1rem;
  	}
  	@include desktop {
  	}
  }
	&-section-headline {
		font-family: $headline;
		color: $primary;
		margin-bottom: .5rem;
		@extend %headline3;	
		@include tablet {
			text-align: left;
		}		
	}
	&-title {
		@extend %slide-up;	
		color: $gray;
		font-family: $headline;
		text-transform: uppercase;
		@extend %headline6;	
		transition: $slow;
		display: block;		
		&.reveal {
			@extend %slide-up-reveal;	
		}		
		&-link {
  		color: $gray;
  		transition: $slow;
			padding: .6rem .5rem;
			display: table;
			&:hover {
				color: $secondary;
				.leadership-title-arrow {
				  fill: $secondary;
				}
			}
			&:focus {
      	box-shadow: inset 0 0 0 2px $green;
			}
		}
		&-arrow {
			width: .65rem;
			max-height: .65rem;
			margin-left: .15rem;
			fill: $gray;
			transition: $slow;
			position: relative;
			top: -.1rem;
		}		
	}
	&-post {
		@extend %slide-up;	
		&.reveal {
			@extend %slide-up-reveal;	
		}
	}
	&-grid {
		background: $graybg;
		width: 100%;
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-content: flex-start;
		align-items: stretch;			
		padding: 1rem;	
		margin-bottom: 1rem;
		&:hover {
			.leadership {
				&-headline {
					color: $secondary;
				}
				&-more {
					color: $secondary;
				}					
			}
		}
		&:focus {
    	box-shadow: 0 0 0 4px $green;
			.leadership {
				&-headline {
					color: $secondary;
				}
			}    	
		}		
	}
	&-headshot {
 		width: 20%;
 		&-img {
 			width: 100%;
 			border-radius: 50%;
 			box-shadow: $shadow;
 		}
 		&-double {
 		   position: relative;
		    width: 25%;
 		   img {
 		     width: 48%;
 		     @include absolute(left 0 top 0);
 		     &:first-of-type {
 		       z-index: 10;
 		     }
 		     &:last-of-type {
 		       right: 0;
 		       left: auto;
 		       z-index: 1;
 		     }
 		   }
 		}
	}
	&-text {
		width: 80%;
		text-align: left;
		padding-left: 1rem;
		&.narrow {
		   width: 75%;
		}
		&.wide {
		  @include tablet {
 		   width: 100%;
		  }
		}		
	}	
	&-author {
		font-size: .9rem;
		color: $gray;
		text-transform: uppercase;
		@include tablet {
			font-size: .7rem;
		}		
		@include desktop {
			font-size: .9rem;
		}			
	}	
	&-headline {
		font-family: $headline;
		color: $primary;
		transition: $slow;
		@extend %headline6;	
	}
	&-blurb {
		color: $gray;
		margin-top: .5rem;
		font-size: 100%;
		@extend %basic-text;	
		line-height: 1.1;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;	
    @include tablet {
      -webkit-line-clamp: 2;
    }    
    @include desktop {
      -webkit-line-clamp: 5;
    }
	}	
	&-read-more {
		font-family: $headline;
		color: $black;
		margin-top: .5rem;
		text-transform: uppercase;
		font-size: .8rem;
		display: block;
		transition: $slow;
		&:hover {
			color: $primary;
		}		
	}
	&-more {
		font-family: $headline;
		color: $black;
		margin-top: 1rem;
		text-transform: uppercase;
		font-size: .8rem;
		display: block;
		@extend %slide-up;	
		&.reveal {
			@extend %slide-up-reveal;	
		}
		&-link {
			color: $gray;
			text-transform: uppercase;
			display: block;
			transition: $slow;	
			@include phone {
				text-align: center;
			}
			&-arrow {
				width: .35rem;
				max-height: .5rem;
				margin-left: .35rem;
				fill: $gray;
				transition: $slow;
			}
			&:hover {
				color: $primary;
				&-arrow {
					fill: $primary;
				}
			}
		}		
	}	
	&-layer {
	  &.executive {
	    .leadership {
	      &-post {
	        @include tablet {
	          width: 50%;
	        }
	      }
	    }
	  }
	}
	&-headshot-header {
	 margin-bottom: 1rem;
	 text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: center;   
  text-align: left;
  margin: 1rem 0;
  @include tablet {
   margin-bottom: 1.5rem;
  }
  .leadership-headshot {
   width: 15%;
  }
 	&-headline {
 		font-family: $headline;
 		color: $gray;
 		transition: $slow;
 		@extend %headline4;	
	  padding-left: 1rem;
 	}  
	}
}
.interior {
  .leadership {
  	padding: 0;
  	@include desktop {
  		padding-bottom: 0;
  	}
  	&-grid {
  		background: $white !important;
  	}  	
  }
}		
