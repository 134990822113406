.modal {
    background-color: $white;
    width: $tablet;
    max-width: 90%;
    padding: 3rem;
    font-size: 1rem;
    line-height: 1.4;
    opacity: 0;
    text-align: center !important;
    position: relative;
    border-radius: 1rem;
    box-shadow: 0 0 1em rgba($black, .25);
    transform: scale(0.7);
    transition: .3s all $slow;
    &_close {
        @include size(2.5rem);
        @include absolute(right -.7rem top -.7rem);
        background: $green;
        color: $white;
        font-size: .9rem;
        padding: .3rem;
        border-radius: 50%;
        display: block;
        cursor: pointer;
        border: none;
        transition: .3s all $slow-curve;
        box-shadow: 0 0 1em rgba($black, .25);
        &:hover {
            background: $blue;
        }
        &-svg {
           width: 100%;
           fill: $white;
        }
    }
    &-headline {
       @extend %headline3;
       line-height: 1;
       color: $green;
       margin-bottom: 1rem;
       @include desktop {
          margin-bottom: 1.5rem;
       }
    }
    &-teaser {
       @extend %basic-text;
       text-align: center;

    }
    &-disclaimer {
        color: $gray;
        font-size: 80%;
        margin-top: 1rem;

        @include desktop {
            margin-top: 2rem;
        }
    }
    .button {
       &-grouping {
           padding-bottom: 0;
       }
       &-text {
          font-size: 115%;
       }
    }
}
.popup_visible {
    .modal {
        transform: scale(1);
        opacity: 1;
        z-index: 9999999999;
    }
}
